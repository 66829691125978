import styles from './login.module.scss';
import useAuthStore from 'store/useAuth';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import { navigate } from 'gatsby';

import React, { useEffect } from 'react';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';
import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';

const getUserRoles = ({ roles }) => roles.map(({ name }) => name);
const userHasRole = ({ roles }, role) => getUserRoles({ roles }).includes(role);

const LoginPage = () => {
  const handleLogin = useAuthStore(state => state.login);
  const currentUser = useAuthStore(state => state.user);

  // Handle login action in state
  const form = useFormState({
    values: {
      email: '',
      password: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.email) {
        errors = {
          ...errors,
          email: 'Benutzername muss ausgefüllt sein',
        };
      }
      if (!values.password) {
        errors = {
          ...errors,
          password: 'Passwort muss ausgefüllt sein',
        };
      }
      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    onSubmit: async ({ email, password }) => {
      try {
        await handleLogin(email, password);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });
  useEffect(() => {
    if (currentUser) {
      const isMa48 = userHasRole(currentUser, 'ma48');
      const isWienEnergie = userHasRole(currentUser, 'wienenergie');

      if (isMa48 || isWienEnergie) {
        navigate('/wochenplanung');
        return;
      }

      navigate('/benutzer');
    }
  }, [currentUser]);

  return (
    <Wrapper>
      <Layout title="Login">
        <div className={styles.container}>
          <Container size="sm">
            <Card>
              <Form className={styles.form} {...form}>
                <InputField
                  className=""
                  name="email"
                  label="Benutzername"
                  type="text"
                  {...form}
                />
                <InputField
                  className=""
                  name="password"
                  label="Passwort"
                  type="password"
                  {...form}
                />
                <FormSubmitButton
                  as={Button}
                  className={styles.submit}
                  {...form}
                >
                  Anmelden
                </FormSubmitButton>
              </Form>
            </Card>
          </Container>
        </div>
      </Layout>
    </Wrapper>
  );
};

export default LoginPage;
